<template>
  <div class="users" id="users">
    <h3><span @click="menu(!desktop)">Users</span> <span @click="addUser()"></span></h3>
    <div :class="workareaWidth > 900 ? 'user_list' : 'user_list_tablet'">
      <div class="list_header">
        <div>email</div>
        <div v-if="width > 600">Last Name</div>
        <div v-if="width > 600">First Name</div>
        <div v-if="workareaWidth > 900">Groups</div>
        <div>Edit</div>
      </div>
      <div class="list_body" v-for="(user,index) in users" :key="index">
        <div>{{ user.email }}</div>
        <div v-if="width > 600">{{ user.firstName }}</div>
        <div v-if="width > 600">{{ user.lastName }}</div>
        <div class="groups" v-if="workareaWidth > 900">
          <transition v-for="groupId in user.groups">
            <div v-if="groups[groupId] !== undefined">{{ groups[groupId].name }}</div>
          </transition>
        </div>
        <div><span class="edit" @click="editUser(index)"></span></div>
        <div class="groups" v-if="workareaWidth <= 900 && width > 600">
          <span>Groups:</span>
          <transition v-for="groupId in user.groups">
            <div v-if="groups[groupId] !== undefined">{{ groups[groupId].name }}</div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, toRefs, watch, onMounted } from 'vue';
let props = defineProps({ users: Object, groups: Object, desktop: Boolean });
const { users, groups, desktop } = toRefs(props);
const emit = defineEmits(['selected', 'addUser', 'appear']);
function menu(i) {
  emit('appear',i);
}
function editUser(i) {
  emit('selected',i);
}
function addUser(i) {
  emit('addUser',i);
}

let workareaWidth = ref();
let width = ref(window.innerWidth)
onMounted(() => {
  workareaWidth.value = document.getElementById('users').offsetWidth;
  width.value = window.innerWidth;
  window.addEventListener('resize', () => {
    workareaWidth.value = document.getElementById('users').offsetWidth;
    width.value = window.innerWidth;
  });
});

watch(() => props.users, (newValue) =>
{
  users.value = newValue;
}, { deep: true });

watch(() => props.groups, (newValue) =>
{
  groups.value = newValue;
}, { deep: true });
</script>

<style lang="scss" scoped>
  .users{
    .user_list{
      padding: 20px 20px 0 20px;
      .list_header{
        font-weight: 500;
      }
      >div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #444;
        border-bottom: 1px solid #bcbcbc;
        >div{
          padding: 8px 0;
          display: flex;
          align-items: center;
        }
        .groups{
          >div{
            height: 37px;
            line-height: 35px;
            border: 1px solid #bcbcbc;
            border-radius: 8px;
            padding: 0 16px;
            background-color: #f2f2f2;
            font-size: 1rem;
            margin: 2px;
            box-sizing: border-box;
          }
        }

        >div:nth-child(1){
          width: 33%;
          word-break: break-word;
          white-space: nowrap;
        }
        >div:nth-child(2){
          width: 15%;
        }
        >div:nth-child(3){
          width: 15%;
        }
        >div:nth-child(4){
          width: 30%;
          display: flex;
          flex-wrap: wrap;
        }
        >div:nth-child(5){
          width: 7%;
          justify-content: center;
          .edit{
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            background-image: url(../../../public/img/edit.svg);
            cursor: pointer;
          }
        }
      }
    }
    .user_list_tablet{
      padding: 20px 20px 0 20px;
      .list_header{
        font-weight: 500;
      }
      >div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #444;
        border-bottom: 1px solid #bcbcbc;
        flex-wrap: wrap;
        >div{
          padding: 8px 0;
          display: flex;
          align-items: center;
          
          .edit{
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            background-image: url(../../../public/img/edit.svg);
            cursor: pointer;
          }
        }
        .groups{
          >div{
            height: 37px;
            line-height: 35px;
            border: 1px solid #bcbcbc;
            border-radius: 8px;
            padding: 0 16px;
            background-color: #f2f2f2;
            font-size: 1rem;
            margin: 2px;
            box-sizing: border-box;
          }
          >span{
            // display: inline-block;
            margin-right: 10px;
          }
        }

        >div:nth-child(1){
          width: 42%;
          word-break: break-word;
          white-space: nowrap;
          @media screen and (max-width: 600px){
            width: calc(100% - 50px);
          }
        }
        >div:nth-child(2){
          width: 25%;
          @media screen and (max-width: 600px){
            width: 50px;
          }
        }
        >div:nth-child(3){
          width: 25%;
        }
        >div:nth-child(4){
          width: 8%;
          justify-content: center;
        }
        >div:nth-child(5){
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
    h3{
      height: 60px;
      line-height: 60px;
      margin: 0px;
      padding: 0 0 0 20px;
      background-color: #f2f2f2;
      position: relative;
      span:last-child{
        height: 30px;
        width: 30px;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-size: 24px;
        background-position: center;
        background-image: url(../../../public/img/plus_gray.svg);
        cursor: pointer;
      }
      @media screen and (max-width: 900px){
        span:first-child{
          display: inline-block;
          height: 100%;
          padding-right: 40px;
          background-image: url(../../../public/img/arr_down.svg);
          background-repeat: no-repeat;
          background-position: center right;
        }
      }
    }
  }
</style>