<template>
    <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.0698 21.75H2.93023C2.55837 21.75 2.25 21.4416 2.25 21.0698C2.25 20.6979 2.55837 20.3895 2.93023 20.3895H21.0698C21.4416 20.3895 21.75 20.6979 21.75 21.0698C21.75 21.4416 21.4416 21.75 21.0698 21.75Z" fill="currentColor"/>
        <path d="M20.1628 21.75H3.83722C3.46535 21.75 3.15698 21.4416 3.15698 21.0698V6.55814C3.15698 3.81907 4.72605 2.25 7.46512 2.25H16.5349C19.274 2.25 20.843 3.81907 20.843 6.55814V21.0698C20.843 21.4416 20.5347 21.75 20.1628 21.75ZM4.51745 20.3895H19.4826V6.55814C19.4826 4.54465 18.5484 3.61047 16.5349 3.61047H7.46512C5.45163 3.61047 4.51745 4.54465 4.51745 6.55814V20.3895Z" fill="currentColor"/>
        <path d="M10.186 16.7616H7.46511C7.09325 16.7616 6.78488 16.4532 6.78488 16.0814C6.78488 15.7095 7.09325 15.4011 7.46511 15.4011H10.186C10.5579 15.4011 10.8663 15.7095 10.8663 16.0814C10.8663 16.4532 10.5579 16.7616 10.186 16.7616Z" fill="currentColor"/>
        <path d="M16.5349 16.7616H13.814C13.4421 16.7616 13.1337 16.4532 13.1337 16.0814C13.1337 15.7095 13.4421 15.4011 13.814 15.4011H16.5349C16.9068 15.4011 17.2151 15.7095 17.2151 16.0814C17.2151 16.4532 16.9068 16.7616 16.5349 16.7616Z" fill="currentColor"/>
        <path d="M10.186 12.6803H7.46511C7.09325 12.6803 6.78488 12.3719 6.78488 12.0001C6.78488 11.6282 7.09325 11.3198 7.46511 11.3198H10.186C10.5579 11.3198 10.8663 11.6282 10.8663 12.0001C10.8663 12.3719 10.5579 12.6803 10.186 12.6803Z" fill="currentColor"/>
        <path d="M16.5349 12.6803H13.814C13.4421 12.6803 13.1337 12.3719 13.1337 12.0001C13.1337 11.6282 13.4421 11.3198 13.814 11.3198H16.5349C16.9068 11.3198 17.2151 11.6282 17.2151 12.0001C17.2151 12.3719 16.9068 12.6803 16.5349 12.6803Z" fill="currentColor"/>
        <path d="M10.186 8.59887H7.46511C7.09325 8.59887 6.78488 8.2905 6.78488 7.91864C6.78488 7.54678 7.09325 7.2384 7.46511 7.2384H10.186C10.5579 7.2384 10.8663 7.54678 10.8663 7.91864C10.8663 8.2905 10.5579 8.59887 10.186 8.59887Z" fill="currentColor"/>
        <path d="M16.5349 8.59887H13.814C13.4421 8.59887 13.1337 8.2905 13.1337 7.91864C13.1337 7.54678 13.4421 7.2384 13.814 7.2384H16.5349C16.9068 7.2384 17.2151 7.54678 17.2151 7.91864C17.2151 8.2905 16.9068 8.59887 16.5349 8.59887Z" fill="currentColor"/>
    </svg>
</template>