<template>
  <div class="Permissions">
    <h3><span @click="menu(!desktop)">Permissions</span>
      <div>
        <span>Select User Group</span>
        <select @change="getPermissionsByGroupId();" v-model="groupId">
          <option v-for="group in groups" :key="group" :value="group.id">{{ group.name }}</option>
        </select>
      </div>
    </h3>
    <div class="business">
      <div :class="chosen == 1 ? 'chosen' : null" @click.stop="chosen = 1">bOpus</div>
      <!-- <div :class="chosen == 2 ? 'chosen' : null" @click.stop="chosen = 2">noDT</div>
      <div :class="chosen == 3 ? 'chosen' : null" @click.stop="chosen = 3">Comms</div> -->
    </div>
    <div class="Permissions_body">
      <div v-if="!local" class="global">
        <div>
          <h4>Global <Toggle v-model="global"/></h4>

          <transition name="unroll">
            <div v-if="global" class="global_body">
              <div class="global_left">
                <p>Use global permissions to give all group members access to all areas with standard permissions.<br/><br/>
                  Use location specific permissions to provide granular access for groups specific to individual locations.</p>
              </div>
              <div class="global_right">
                <div class="header_row">
                  <div>Locations</div>
                  <div>Libraries</div>
                  <div>Sections</div>
                  <div>Books</div>
                  <div>Pages</div>
                </div>
                <div class="checks">
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                </div>
                <div class="checks">
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                </div>
                <div class="checks">
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                </div>
                <div class="checks">
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                  <label>
                    <input type="checkbox"/>
                    <i class="checkmark"></i>
                  </label>
                </div>
              </div>
            </div>
          </transition>

        </div>
      </div>
      <div class="specific">
        <h4>Location Specific<!--<Toggle v-model="local"/>--></h4>
        <div v-if="local" class="tree_wrapper">
          <div class="tree_header">
            <p>{{ projectName }}</p>
            <div class="global_Permissions">
              <label>
                <span>Inherit</span>
                <input type="checkbox" v-model="store.state.allPermissionsInherit"/>
                <i class="checkmark"></i>
              </label>
              <label>
                <span>View</span>
                <input type="checkbox" v-model="store.state.allPermissionsView"/>
                <i class="checkmark"></i>
              </label>
              <label>
                <span>Edit</span>
                <input type="checkbox" v-model="store.state.allPermissionsEdit"/>
                <i class="checkmark"></i>
              </label>
              <label>
                <span>Add</span>
                <input type="checkbox" v-model="store.state.allPermissionsAdd"/>
                <i class="checkmark"></i>
              </label>
              <label>
                <span>Delete</span>
                <input type="checkbox" v-model="store.state.allPermissionsDelete"/>
                <i class="checkmark"></i>
              </label>
            </div>
          </div>
          <tree-item :node="permissionsTree" :treeNode="TreeNode" :depth="0">
            <tree-node :is="TreeNode" :node="permissionsTree" :depth="0"/>
          </tree-item>
<!--          <tree-item v-if="permissionsTree?.children?.length > 0" v-for="child in permissionsTree?.children" :node="child" :treeNode="TreeNode" :depth="0">-->
<!--            <tree-node :node="child"/>-->
<!--          </tree-item>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref, defineEmits, watch, inject, toRefs, nextTick} from 'vue';
import '../../../src/assets/default.scss';
import Toggle from '@vueform/toggle';
import {useStore} from "vuex";
import TreeItem from '../treeview/source/TreeItem';
import TreeNode from '../treeview/UserManagementTreeNode';

const store = useStore();
store.state.allPermissionsInherit = false;
store.state.allPermissionsView = false;
store.state.allPermissionsEdit = false;
store.state.allPermissionsAdd = false;
store.state.allPermissionsDelete = false;
store.state.permissionsByLocationId = {};
store.state.permissionsNodeShowState = {};
const apiPath = inject('apiPath');
const projectName = localStorage.getItem('projectName');

const props = defineProps({groups: Object, desktop: Boolean});
let groupId = ref(null);
const { groups } = toRefs(props);
if (groups.value.length > 0) {
  groupId.value = groups.value[0].id;
  getPermissionsByGroupId();
}
const emit = defineEmits(['selected','appear']);

let chosen = ref(1);
let global = ref(false);
let local = ref(true);

function menu(i) {
  emit('appear',i);
}
function editGroup(i) {
  emit('selected',i);
}
watch(global, () => {
  local.value = !global.value;
});
watch(local, () => {
  global.value = !local.value;
});
watch(() => props.groups, (newValue) =>
{
  groups.value = newValue;
}, { deep: true });

watch(() => store.state.allPermissionsInherit, (newValue, oldValue) => {
  if (newValue && !oldValue) {
    store.state.allPermissionsView = false;
    store.state.allPermissionsEdit = false;
    store.state.allPermissionsAdd = false;
    store.state.allPermissionsDelete = false;
    sendFetchPermission('deleteByFilter', 'disinherit');
    sendFetchPermission('deleteByFilter', 'view');
    sendFetchPermission('deleteByFilter', 'edit');
    sendFetchPermission('deleteByFilter', 'add');
    sendFetchPermission('deleteByFilter', 'delete');
  } else if (oldValue && !newValue) {
    sendFetchPermission('multipleAdd', 'disinherit');
  }
});
watch(() => store.state.allPermissionsView, (newValue, oldValue) => {
  if (newValue && !oldValue) {
    store.state.allPermissionsInherit = false;
    sendFetchPermission('multipleAdd', 'view');
    sendFetchPermission('multipleAdd', 'disinherit');
  } else if (oldValue && !newValue) {
    console.log('wtf2');
    sendFetchPermission('deleteByFilter', 'view');
  }
});
watch(() => store.state.allPermissionsEdit, (newValue, oldValue) => {
  if (newValue && !oldValue) {
    store.state.allPermissionsInherit = false;
    sendFetchPermission('multipleAdd', 'edit');
    sendFetchPermission('multipleAdd', 'disinherit');
  } else if (oldValue && !newValue) {
    sendFetchPermission('deleteByFilter', 'edit');
  }
});
watch(() => store.state.allPermissionsAdd, (newValue, oldValue) => {
  if (newValue && !oldValue) {
    store.state.allPermissionsInherit = false;
    sendFetchPermission('multipleAdd', 'add');
    sendFetchPermission('multipleAdd', 'disinherit');
  } else if (oldValue && !newValue) {
    sendFetchPermission('deleteByFilter', 'add');
  }
});
watch(() => store.state.allPermissionsDelete, (newValue, oldValue) => {
  if (newValue && !oldValue) {
    store.state.allPermissionsInherit = false;
    sendFetchPermission('multipleAdd', 'delete');
    sendFetchPermission('multipleAdd', 'disinherit');
  } else if (oldValue && !newValue) {
    sendFetchPermission('deleteByFilter', 'delete');
  }
});

function sendFetchPermission(action, permission, entity_type = 'location') {
  sendFetch(
      'entity/permission/' + action,
      {
        "group_id": store.state.permissionsGroupdId,
        "entity_type": entity_type,
        "permission": permission
      }
  );
}

let permissionsTree = ref({});
async function getPermissionsByGroupId(){
  store.state.permissionsByLocationId = {};
  store.state.permissionsNodeShowState = {};
  store.state.permissionsGroupdId = groupId.value;
  permissionsTree.value = await sendFetch(
      'entity/permission/getGroupPermissionsTree',
      {
        "groupId": groupId.value
      }
  );
}
async function sendFetch(url, data = null) {
  let formData = new FormData();
  formData.append('token', store.state.usertoken);
  if (data !== null) {
    Object.keys(data).forEach(function(key) {
      formData.append(key, data[key]);
    });
  }
  return await fetch(`${apiPath}` + url, {
    method: 'POST',
    body: formData,
  }).then((res) => res.json()).then((response) => {
    if (response.result) {
      return response.result;
    } else {
      console.log('Looks like there was a problem: \n', response.error);
      if (response.error === 'Wrong token') {
        window.location.reload();
      }
      return false;
    }
  }).catch((error) => {
    console.log('Looks like there was a problem: \n', error);
    return false;
  });
}
</script>

<style lang="scss" scoped>
.tree_header{
  border-bottom: 1px solid #bcbcbc;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 60px;
  background-color: #fff;
  z-index: 1;
  @media screen and (max-width: 480px) {
    top: 140px;
    padding-left: 0px;
  }
  p{
    display: flex;
    align-self: end;
    margin: 0px;
  }
  .global_Permissions{
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-left: auto;
    margin-bottom: 36px;
    label{
      position: relative;
      margin: 5px 0;
      width: 100px;
      text-indent: 20px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      @media screen and (max-width: 740px) {
        width: 50px;
        height: 80px;
        flex-shrink: 0;
        flex-grow: 1;
        justify-content: flex-end;
      }
      @media screen and (max-width: 480px) {
        width: 40px;
      }
      span{
        height: 44px;
        line-height: 44px;
        text-align: center;
        text-indent: 0px;
        @media screen and (max-width: 480px) {
          transform: rotate(270deg);
          position: absolute;
          left: 0px;
          height: 44px;
          width: 100%;
          top: 11px;
        }
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      height: 20px;
      width: 20px;
      background-color: #fff;
      border: 2px solid #787878;
      box-sizing: border-box;
      border-radius: 6px;
      align-self: center;
      
        @media screen and (max-width: 480px) {
          margin-top: 55px;
        }
    }
    label input:checked ~ .checkmark {
      background-color: #2884C7;
      border: 2px solid #2884C7;
    }
    .checkmark:after {
      content: "";
      display: none;
    }
    label input:checked ~ .checkmark:after {
      display: block;
    }
    label .checkmark:after {
      margin-left: -1px;
      margin-top: -1px;
      width: 18px;
      height: 18px;
      border-radius: 6px;
      background-image: url(../../../public/img/check.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px;
    }
  }
}
.Permissions{
  height: 100%;
  position: relative;
  h3{
    height: 60px;
    line-height: 60px;
    margin: 0px;
    padding: 0 20px 0 20px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 64px;
    right: 0px;
    left: 340px;
    z-index: 1;
    @media screen and (max-width: 900px) {
      top: 52px;
      left: 0px;
      >span:first-child{
        display: inline-block;
        height: 100%;
        padding-right: 40px;
        background-image: url(../../../public/img/arr_down.svg);
        background-repeat: no-repeat;
        background-position: center right;
        @media screen and (max-width: 480px) {
          height: 60px;
          line-height: 60px;
        }
      }
    }
    @media screen and (max-width: 480px) {
      top: 44px;
      flex-direction: column;
      align-items: baseline;
      height: 140px;
      justify-content: flex-start;
    }
    div{
      font-size: 1rem;
      font-weight: 400;
      width: 50%;
      display: flex;
      align-items: center;
      @media screen and (max-width: 900px) {
        span{
          line-height: 1.2rem;
        }
      }
      @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
      }
      select{
        margin-left: 10px;
        height: 40px;
        height: 38px;
        border: 1px solid #bcbcbc;
        border-radius: 8px;
        outline: none;
        flex-grow: 1;
        font-size: 1rem;
        color: #787878;
        text-indent: 8px;
        @media screen and (max-width: 480px) {
          margin-left: 0px;
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }
  .global{
    padding: 20px;
    >div{
      background-color: #7878780D;
      border-radius: 10px;
      padding: 50px 20px 20px;
      h4{
        font-weight: 500;
        display: flex;
        align-items: center;
        height: 45px;
        line-height: 45px;
        border-bottom: 1px solid #bcbcbc;
        margin: 0px;
        >div{
          margin-left: 40px;
        }
      }
    }
    .global_body{
      display: flex;
      flex-direction: row;
      .global_left{
        width: 45%;
        p{
          margin: 10 0 0 0px;
        }
      }
      .global_right{
        width: 55%;
        columns: 5;
        display: flex;
        flex-direction: row;
        justify-content: end;
        .header_row{
          display: flex;
          flex-direction: column;
          height: 100%;
          >div{
            height: 40px;
            line-height: 40px;
            margin: 5px 0;
          }
        }
        .checks{
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100px;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          .checkmark {
            position: absolute;
            top: calc(50% - 10px);
            left: calc(50% - 10px);
            height: 20px;
            width: 20px;
            background-color: #fff;
            border: 2px solid #787878;
            box-sizing: border-box;
            border-radius: 6px;
          }
          label{
            position: relative;
            margin: 5px 0;
            width: 100%;
            text-indent: 20px;
            height: 40px;
            cursor: pointer;
          }
          label input:checked ~ .checkmark {
            background-color: #2884C7;
            border: 2px solid #2884C7;
          }
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
          label input:checked ~ .checkmark:after {
            display: block;
          }
          label .checkmark:after {
            top: -1px;
            left: -1px;
            width: 18px;
            height: 18px;
            border-radius: 6px;
            background-image: url(../../../public/img/check.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 12px;
          }
        }
      }
    }
  }
  .specific{
    padding: 20px;
    h4{
      font-weight: 500;
      display: flex;
      align-items: center;
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid #bcbcbc;
      margin: 0px;
      >div{
        margin-left: 40px;
      }
    }
    .tree_wrapper{
      @media screen and (max-width: 480px) {
        padding-top: 15px;
      }
    }
  }
  .business{
    width: 100%;
    height: 44px;
    border-bottom: 4px solid #bcbcbc;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin-bottom: 15px;
    >div{
      height: 44px;
      box-sizing: border-box;
      line-height: 40px;
      padding: 0 20px;
      cursor: pointer;
    }
    >div.chosen{
      border-bottom: 4px solid#2884c7;
    }
  }
}

// .unroll-enter-active,
// .unroll-leave-active {
//   transition: all 0.2s ease;
// }

// .unroll-enter-from,
// .unroll-leave-to {
//   transform: translateY(-100%) scaleY(0%);
// }
</style>