<template>
  <main class="main_v8 user_management">

    <transition name="sidebar_slide">
      <div class="sidebar" v-if="desktop">
        <div class="my_settings">
          <p @click="here"><span></span>Settings</p>
          <div class="user">

            <!-- <div class="no_image" v-if="user.metadata.image == undefined || user.metadata.image == ''">{{user.metadata.name[0] + user.metadata.surname[0]}}</div> -->
            <div class="no_image" v-if="user.metadata.image == undefined || user.metadata.image == ''"></div>
            <img v-else height="50" width="50" style="border-radius: 50%;" :src="user.metadata.image">
            <div>
              <p class="tileTitle">{{user.metadata.name + ' ' + user.metadata.surname}}</p>
              <span class="tileSubtext">{{user.role[0]}}</span>
            </div>
          </div>
          <p>My Settings</p>
          <p class="menu_item" :class="displayScreen === 'profile' ? 'active' : null"
            @click="changeDisplayed('profile')">
            <profileSVG />Profile
          </p>
          <p class="menu_item" :class="displayScreen === 'password' ? 'active' : null"
            @click="changeDisplayed('password')">
            <passwordSVG />Password
          </p>
          <!-- <p class="menu_item"><notificationsSVG/>Notifications</p> -->
        </div>
        <div class="divider"></div>
        <div v-if="store.state.user.role.includes('bOpus_admin') || store.state.user.role.includes('admin')"
          class="company_settings">
          <p>Company Settings</p>
          <p class="menu_item" :class="displayScreen === 'users' ? 'active' : null" @click="changeDisplayed('users');">
            <usersSVG />Users
          </p>
          <p class="menu_item" :class="displayScreen === 'groups' ? 'active' : null" @click="changeDisplayed('groups')">
            <groupsSVG />User Groups
          </p>
          <p class="menu_item" :class="displayScreen === 'permissions' ? 'active' : null"
            @click="changeDisplayed('permissions')">
            <permissionsSVG />Permissions
          </p>
          <p class="menu_item">
            <billingSVG />Account & Billing
          </p>
          <p class="menu_item">
            <companySVG />Company Info
          </p>
        </div>
      </div>
    </transition>

    <div class="work_area" :style="{left: (width <= 900 ? '0px' : '340px')}">
      <profile v-if="displayScreen === 'profile'" @appear="menuToggle" :desktop="desktop" :user="user"
        @change_email="change_email = true"></profile>
      <password v-if="displayScreen === 'password'" @appear="menuToggle" :desktop="desktop"></password>
      <users v-if="displayScreen === 'users'" @appear="menuToggle" :desktop="desktop" :users="userList"
        :groups="groupsByIdList" @selected="userEdit" @addUser="newUser = true"></users>
      <groups v-if="displayScreen === 'groups'" @appear="menuToggle" :desktop="desktop" :groups="group_list"
        @selected="groupEdit" @addGroup="newGroup = true" @del="groupDelete"></groups>
      <prem v-if="displayScreen === 'permissions'" @appear="menuToggle" :desktop="desktop" :groups="group_list"></prem>

      <transition name="slide-right">
        <div class="slider_modal" v-if="user_edit >= 0 || group_edit >= 0 || newUser || newGroup"
          :style="user_delete >= 0 || group_delete >= 0 || user_groups >= 0 || user_groups == 'new user' || addNotice ? { zIndex: '1' } : null">

          <h3 v-if="user_edit >= 0">Edit User <span class="delete" v-if="user_edit >= 0"
              @click.stop="user_delete = user_edit"></span></h3>
          <h3 v-if="newUser" @click="here()">Add User</h3>

          <h3 v-if="group_edit >= 0 || newGroup">Add / Edit Group <span class="delete" v-if="group_edit >= 0"
              @click.stop="group_delete = group_edit"></span></h3>

          <div class="modal_body" v-if="user_edit >= 0 || newUser">
            <div class="field toggler">
              <p>Allow Login</p>
              <Toggle v-model="new_user.allowLogin" />
            </div>
            <!--            <div class="field toggler">-->
            <!--              <p>Visible to others</p>-->
            <!--              <Toggle v-model="new_user.visibleToOthers"/>-->
            <!--            </div>-->
            <div class="field toggler">
              <p>Admin</p>
              <Toggle v-model="new_user.admin" />
            </div>
            <div v-if="newUser" class="field short_field">
              <p>Email</p>
              <input v-if="new_user.id !== ''" type="email" v-model="new_user.email" disabled />
              <input v-else type="email" v-model="new_user.email" />
            </div>
            <div v-else class="field short_field">
              <p>Email</p>
              <p>{{ new_user.email }}</p>
            </div>
            <div v-if="newUser" class="field short_field">
              <p>First Name</p>
              <input v-if="new_user.id !== ''" type="text" v-model="new_user.firstName" disabled />
              <input v-else type="text" v-model="new_user.firstName" />
            </div>
            <div v-if="newUser" class="field short_field">
              <p>Last Name</p>
              <input v-if="new_user.id !== ''" type="text" v-model="new_user.lastName" disabled />
              <input v-else type="text" v-model="new_user.lastName" />
            </div>

            <!--&lt;!&ndash;            temp disable&ndash;&gt;-->
            <!--            <div class="checkboxes">-->
            <!--              <p>User Groups Assigned</p>-->
            <!--              <div class="checks">-->
            <!--                <label v-for="(group,index) in group_list" :key="index">-->
            <!--                  {{ group.name }}-->
            <!--                  <input type="checkbox"/>-->
            <!--                  <i class="checkmark"></i>-->
            <!--                </label>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="field short_field">
              <p>User Groups</p>
              <span v-if="user_edit >= 0" class="edit_toggle"
                @click.prevent="user_groups = user_edit; editUserGroups();"></span>
              <span v-else class="edit_toggle" @click="user_groups = 'new user'; editUserGroups();"></span>
            </div>

            <!-- <div class="groups_field" :class="!changePassword && !newUser ? 'groups_field_disabled' : null"> -->
            <div class="groups_field" v-if="newUser">
              <div class="settings">
                <!-- <div class="toggler_field" v-if="user_edit >= 0" style="margin-bottom: 10px;"><Toggle v-model="changePassword" class="new_toggle"/><span>Change Password</span></div> -->
                <div class="password_field">
                  <p>New Password</p>
                  <input :type="reveal ? 'text' : 'password'" v-model="newUserPassword" @paste="onPaste" @focus="complexity.focus = 1" @blur="complexity.focus = 0"
                    onkeypress="return event.charCode != 32">
                  <span class="eye" @click="reveal = !reveal">
                    <span v-if="reveal"></span>
                  </span>
                  <stages class="stages" :complexity="complexity"></stages>
                </div>
                <div class="toggler_field">
                  <Toggle v-model="newUserRequireChangePass" />
                  <span>Require user to change password after sign in</span>
                </div>
                <div class="toggler_field">
                  <Toggle v-model="newUserSendEmail" /><span>Send password to user email after save</span>
                </div>
                <div class="toggler_field">
                  <Toggle v-model="newUserSendAdminEmail" /><span>Copy me on password email</span>
                </div>
              </div>
            </div>

            <div class="buttons">
              <div class="button secondary" @click.stop="newUserPassword = ''; closeAll()">Cancel</div>
              <div v-if="!saveNewUserButton && newUser" class="button disabled">Save</div>
              <div v-else class="button" @click="checkUserExist();/*saveUser*/">Save</div>
            </div>
          </div>

          <div class="modal_body" v-if="group_edit >= 0 || newGroup">
            <div class="field short_field">
              <p>Group Name</p><input type="text" v-model="new_group.name" />
            </div>
            <div class="field short_field">
              <p>Description</p><textarea type="text" v-model="new_group.desc"></textarea>
            </div>
            <div class="buttons">
              <div class="button secondary" @click.stop="closeAll()">Cancel</div>
              <div class="button" @click="saveGroup">Save</div>
            </div>
          </div>
        </div>
      </transition>

      <!-- <transition name="slide-right">
        <div class="slider_modal" v-if="change_email">
          <h3>Change email <span class="close" @click.stop="change_email = false"></span></h3>
          <div class="modal_body">
            <div class="field short_field">
              <p>New email</p><input type="email" v-model="new_email"/>
            </div>
            <div class="buttons">
              <div class="button secondary" @click.stop="closeAll()">Cancel</div>
              <div class="button disabled">change</div>
            </div>
          </div>
        </div>
      </transition> -->

      <div class="edit_groups" v-if="user_groups >= 0 || user_groups == 'new user'">
        <h3 v-if="user_edit >= 0">{{new_user.firstName + ' ' + new_user.lastName}}<span
            @click.stop="user_groups = -1"></span></h3>
        <h3 v-else>User Groups<span @click.stop="user_groups = -1"></span></h3>
        <h3>User Groups</h3>
        <div class="groups_access_list">
          <label v-for="group in group_list" :key="group">
            {{ group.name }}
            <input type="checkbox" v-model="user_group_list[group.id]" />
            <input type="checkbox" />
            <i class="checkmark"></i>
          </label>
        </div>
        <div class="buttons">
          <div class="button secondary" @click.stop="user_groups = -1; user_group_list = {};">cancel</div>
          <div class="button" @click.prevent="user_groups = -1;">confirm</div>
        </div>
      </div>

      <div class="add_notice" v-if="addNotice">
        <h3>Account Notice<span @click.stop="addNotice = false"></span></h3>
        <p style="font-weight: 500;">User Account Already Exists</p>
        <p>This user email address is an active account within Wave7 and may be in use in other domains.</p>
        <p>You may add them to your domain, but you will not be able to change user data or password.
          If the user does not have access to their password, have them follow the lost password procedure from the
          login page of your domain.
        </p>
        <div class="buttons">
          <div class="button secondary" @click.stop="addNotice = false">cancel</div>
          <div class="button" @click.stop="saveUser">save</div>
        </div>
      </div>

      <div class="delete_entity" v-if="user_delete >= 0 || group_delete >= 0">
        <h3 v-if="group_delete >= 0">Delete Group <span @click.stop="group_delete = -1"></span></h3>
        <h3 v-if="user_delete >= 0">Delete User <span @click.stop="user_delete = -1"></span></h3>
        <p v-if="group_delete >= 0 && !permission"><span
            style="font-weight: 500; margin-bottom: 20px; display: inline-block;">Group has Users and can’t be
            deleted.</span><br />In order to delete Group, you must remove all users from Group</p>
        <p v-if="group_delete >= 0 && permission"><span
            style="font-weight: 500; margin-bottom: 20px; display: inline-block;">Group will be deleted!</span></p>
        <p v-if="user_delete >= 0"><span style="font-weight: 500; margin-bottom: 20px; display: inline-block;">User will
            be deleted!</span><br />User will lose ALL access to bOpus AND their content.</p>

        <div class="delete_user" v-if="user_delete >= 0 && permission">
          <label>
            <input type="checkbox" v-model="toDelete" />
            <i class="checkmark"></i>
            Delete User
          </label>
          <input type="text" v-model="deletePattern" placeholder="Type DELETE to confirm">
        </div>

        <div class="delete_user" v-if="group_delete >= 0 && permission">
          <label>
            <input type="checkbox" v-model="toDelete" />
            <i class="checkmark"></i>
            Delete Group
          </label>
          <input type="text" v-model="deletePattern" placeholder="Type DELETE to confirm">
        </div>

        <div v-if="group_delete >= 0 && !permission || user_delete >= 0 && !permission" class="button single"
          @click.stop="closeAll()">done</div>
        <div v-if="user_delete >= 0 && permission" class="buttons">
          <div class="button secondary" @click.stop="deleteCancel()">cancel</div>
          <div class="button" :class="[!toDelete || deletePattern.toLowerCase() != 'delete' ? 'disabled' : null]"
            @click="deleteConfirm">confirm</div>
        </div>
        <div v-if="group_delete >= 0 && permission" class="buttons">
          <div class="button secondary" @click.stop="deleteCancel()">cancel</div>
          <div class="button" :class="[!toDelete || deletePattern.toLowerCase() != 'delete' ? 'disabled' : null]"
            @click="deleteGroupConfirm">confirm</div>
        </div>
      </div>

    </div>
    <div class="context_shadow"
      v-if="user_edit >= 0 || group_edit >= 0 || user_delete >= 0 || group_delete >= 0 || newUser || newGroup || change_email || desktop && width <= 900"
      @click.stop="closeAll()"></div>
  </main>
</template>

<script setup>
import {inject, ref, watch, onMounted} from "vue";
import { useStore } from 'vuex';
import stages from '../components/password/password.vue';
import users from '../components/users/users.vue';
import groups from '../components/users/groups.vue';
import prem from '../components/users/permissions.vue';
import profile from '../components/users/profile.vue';
import password from '../components/users/password.vue';
import '../../src/assets/default.scss';
import Toggle from '@vueform/toggle';
import profileSVG from '../components/users/svg/profile.vue';
import billingSVG from '../components/users/svg/billing.vue';
import companySVG from '../components/users/svg/company.vue';
import groupsSVG from '../components/users/svg/groups.vue';
import notificationsSVG from '../components/users/svg/notifications.vue';
import passwordSVG from '../components/users/svg/password.vue';
import permissionsSVG from '../components/users/svg/permissions.vue';
import usersSVG from '../components/users/svg/users.vue';
import { SuperTokensController as STC } from '../services/SuperTokensController.js';
import { v4 as uuidv4 } from 'uuid';

const store = useStore();

// if (!store.state.user.role.includes('bOpus_admin')) {
//   window.location.href = "/";
// }
const apiPath = inject('apiPath');
const user =  store.getters['getUser'];
let complexity = ref({
  'focus':0,
  'amount':false,
  'case':false,
  'number':false,
  'special':false
});

let newUserRequireChangePass = ref(false);
let newUserSendEmail = ref(false);
let newUserSendAdminEmail = ref(false);

let reveal = ref(false);
let displayScreen = ref((user.metadata.changePass === 'true' ? 'password' : 'profile'));
let permission = ref(true);
let newUser = ref(false);
let newGroup = ref(false);
let change_email = ref(false);
let user_edit = ref(-1);
let newUserPassword = ref('');
let user_groups = ref(-1);
let group_edit = ref(-1);
let user_delete = ref(-1);
let group_delete = ref(-1);
let deletePattern = ref('');
let new_email = ref('');
let toDelete = ref(false);
let addNotice = ref(false);
let desktop = ref(false);
let width = ref(window.innerWidth);
let saveNewUserButton = ref(false);

const userDetails = {
  id: "",
  email: "",
  lastName: "",
  firstName: "",
  groups: [],
  allowLogin: true,
  admin: false,
}
const groupDetails = {
  id: "",
  name: "",
  desc: "",
}
let new_user = ref({ ...userDetails});
let new_group = ref({ ...groupDetails});
let userList = ref([]);
let groupsByIdList = ref({});

reloadUsersList();

onMounted(() => {
  isDesktop();
  window.addEventListener('resize', () => {
    isDesktop();
    width.value = window.innerWidth;
  });
});

function isDesktop() {
  if (window.innerWidth <= 900) {
    desktop.value = false
  } else {
    desktop.value = true;
  }
}
function changeDisplayed(screen) {
  displayScreen.value = screen;
  if (width.value <= 900) {
    desktop.value = false
  }
}
function reloadUsersList() {
  STC.getTenantUsers().then((response) => {
    userList.value = [];
    response.forEach((element) => {
      let user = { ...userDetails };
      user.id = element.id;
      user.email = element.emails[0];
      user.firstName = element.metadata.name;
      user.lastName = element.metadata.surname;
      user.admin = element.roles.includes('admin');
      user.allowLogin = !element.roles.includes('disabled');
      user.groups = [];
      userList.value.push(user);
    });
    sendFetch(
        'entity/permission/getUsersGroups'
    ).then((response) => {
      if (response !== false) {
        userList.value.forEach((element) => {
          element.groups = response[element.id];
        });
      }
    });
  });
}

let group_list = ref([]);
reloadGroupsList();
function reloadGroupsList() {
  sendFetch(
      'entity/group/find'
  ).then((response) => {
    group_list.value = [];
    groupsByIdList.value = {};
    if (response !== false) {
      response.forEach((element) => {
        let group = { ...groupDetails };
        group.id = element.id;
        group.name = element.name;
        group.desc = element.description;
        group_list.value.push(group);
        groupsByIdList.value[group.id] = group;
      });
    }
  });
}
function menuToggle(i) {
  if (window.innerWidth <= 900) {
    desktop.value = i;
  }
}
function userEdit(i) {
  new_user.value = { ...userList.value[i] };
  user_edit.value = i;
}
function here() {
  console.log(new_user.value,'user')
}
function groupEdit(i) {
  new_group.value = { ...group_list.value[i] };
  group_edit.value = i;
}
function groupDelete(i) {
  group_delete.value = i;
}
function deleteCancel() {
  user_delete.value = -1;
  group_delete.value = -1;
  deletePattern.value = '';
  toDelete.value = false;
}
function onPaste(e) {
	e.preventDefault();
	let pastedText = (e.clipboardData || window.clipboardData).getData('text');
	pastedText = pastedText.split(' ').join('');
	e.clipboardData.setData('text/plain', pastedText);
	document.execCommand("insertHTML", false, pastedText);
};
async function deleteConfirm() {
  if (!toDelete.value || deletePattern.value.toLowerCase() !== 'delete') {
    return;
  }
  let deleteResult = await STC.deleteTenantUser(userList.value[user_delete.value].id);
  if (deleteResult && deleteResult.result) {
    console.log(deleteResult.message);
    reloadUsersList();
    deleteCancel();
    closeAll();
	  store.commit('pageSaved');
  }
};
async function deleteGroupConfirm() {
  if (!toDelete.value || deletePattern.value.toLowerCase() !== 'delete') {
    return;
  }
  let deleteResult = await sendFetch(
      'entity/group/delete',
      {
        "id": group_list.value[group_delete.value].id
      }
  );
  if (deleteResult) {
    console.log(deleteResult);
    reloadGroupsList();
    deleteCancel();
    closeAll();
	  store.commit('pageSaved');
  }
};
function closeAll() {
  user_edit.value = -1;
  group_edit.value = -1;
  user_delete.value = -1;
  group_delete.value = -1;
  user_groups.value = -1;
  newUser.value = false;
  newGroup.value = false;
  change_email.value = false;
  addNotice.value = false;
  new_user.value = { ...userDetails };
  new_group.value = { ...groupDetails }
  user_group_list.value = {};
  newUserPassword.value = '';
  newUserRequireChangePass.value = false;
  newUserSendEmail.value = false;
  newUserSendAdminEmail.value = false;
  if (width.value <= 900) {
    desktop.value = false
  }
};
async function checkUserExist() {
  if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(new_user.value.email) && new_user.value.firstName != '' && new_user.value.lastName != '' && newUserPassword.value != '' ){
    store.commit('savingPage');
    let checkUserId = await STC.checkEmailExist(new_user.value.email);
    if (checkUserId && new_user.value.id === '') {
      addNotice.value = true;
      store.commit('savingError');
    } else {
      await saveUser();
      store.commit('pageSaved');
    }
    complexity.value = {
      'amount':false,
      'case':false,
      'number':false,
      'special':false
    };
  } else{
    console.log('SOMETHING IS EMPTY OR EMAIL IS WRONG')
  }
}
async function saveUser() {
  if (new_user.value.id !== '') {
    let oldData = userList.value[user_edit.value];
    // if (oldData.firstName !== new_user.value.firstName || oldData.lastName !== new_user.value.lastName) {
    //   await STC.setUserMetadata(new_user.value.id, {'name': new_user.value.firstName, 'surname': new_user.value.lastName});
    // }
    if (oldData.allowLogin !== new_user.value.allowLogin || oldData.admin !== new_user.value.admin) {
      await STC.setUserRoles(new_user.value.id, {'disabled': !new_user.value.allowLogin, 'admin': new_user.value.admin});
      if (user.id === new_user.value.id && !user.role.includes('bOpus_admin') && (!new_user.value.allowLogin || !new_user.value.admin)) {
        window.location.href = "/";
        return;
      }
    }
    await saveUserGroups(new_user.value.id);
  } else {
    let addedUserId = await STC.addUser({'email': new_user.value.email, 'password': newUserPassword.value});
    if (new_user.value.firstName !== '' || new_user.value.lastName !== '') {
      if (newUserRequireChangePass.value) {
        await STC.setUserMetadata(addedUserId, {'name': new_user.value.firstName, 'surname': new_user.value.lastName, 'changePass': 'true'});
      } else {
        await STC.setUserMetadata(addedUserId, {'name': new_user.value.firstName, 'surname': new_user.value.lastName});
      }
    }
    await STC.setUserRoles(addedUserId, {'disabled': !new_user.value.allowLogin, 'admin': new_user.value.admin});
    await saveUserGroups(addedUserId);

    if (newUserSendEmail.value) {
      await sendFetch(
          'sendEmail/account_created_email',
          {
            "emails": JSON.stringify([new_user.value.email]),
            "subject": 'Welcome to bOpus',
            "userEmail": new_user.value.email,
            "userPassword": newUserPassword.value
          }
      );
    }
    if (newUserSendAdminEmail.value) {
      let adminName = store.state.user.metadata?.name !== undefined ? store.state.user.metadata?.name : '';
      adminName += (adminName !== '') ? ' ' : '';
      adminName += store.state.user.metadata?.surname !== undefined ? store.state.user.metadata?.surname : '';
      await sendFetch(
          'sendEmail/admin_notification_of_account_created_email',
          {
            "emails": JSON.stringify([store.state.user.email]),
            "subject": 'New User Added to bOpus!',
            "adminName": adminName,
            "userEmail": new_user.value.email,
            "userPassword": newUserPassword.value
          }
      );
    }
    newUserPassword.value = '';
  }
  reloadUsersList();

  closeAll();
}
async function saveGroup() {
  if (new_group.value.id !== '') {
	  store.commit('savingPage');
    let oldData = group_list.value[group_edit.value];
    if (oldData.name !== new_group.value.name || oldData.desc !== new_group.value.desc) {
      console.log('edit is not working right now');
      await sendFetch(
          'entity/group/edit',
          {
            "id": new_group.value.id,
            "name": new_group.value.name,
            "description": new_group.value.desc,
          }
      );
		store.commit('pageSaved');
    }
  } else {
    await sendFetch(
        'entity/group/add',
        {
          "id": uuidv4(),
          "name": new_group.value.name,
          "description": new_group.value.desc,
        }
    );
		store.commit('pageSaved');
  }
  reloadGroupsList();

  closeAll();
}
let user_group_list = ref({});
function editUserGroups(){
  if (Object.keys(user_group_list.value).length !== 0) {
    return;
  }
  user_group_list.value = {};
  if (group_list.value.length > 0) {
    group_list.value.map((group) => {
      if (user_groups.value !== 'new user') {
        user_group_list.value[group.id] = userList.value[user_groups.value].groups.includes(group.id);
      } else {
        user_group_list.value[group.id] = false;
      }
    });
  }
}
async function saveUserGroups(userId){
  const groupsIds = Object.entries(user_group_list.value).filter(([key, value]) => value === true).map(([key]) => key);
  await sendFetch(
      'entity/permission/setUsersGroups',
      {
        "usersGroups": JSON.stringify({ [userId]: groupsIds })
      }
  );
  user_group_list.value = {};
}
async function sendFetch(url, data = null) {
  let formData = new FormData();
  formData.append('token', store.state.usertoken);
  if (data !== null) {
    Object.keys(data).forEach(function(key) {
      formData.append(key, data[key]);
    });
  }
  return await fetch(`${apiPath}` + url, {
    method: 'POST',
    body: formData,
  }).then((res) => res.json()).then((response) => {
    if (response.result) {
      return response.result;
    } else {
      console.log('Looks like there was a problem: \n', response.error);
      if (response.error === 'Wrong token') {
        window.location.reload();
      }
      return false;
    }
  }).catch((error) => {
    console.log('Looks like there was a problem: \n', error);
    return false;
  });
}
function watchPass() {
	let hasNumber = /\d/;
	let uppercase = /[A-Z]/;
	let character = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
	if (newUserPassword.value.length >= 8 ) {
		complexity.value.amount = true;
	} else {
		complexity.value.amount = false;
  }
  if (hasNumber.test(newUserPassword.value)) {
		complexity.value.number = true;
	} else {
		complexity.value.number = false;
  }
  if (uppercase.test(newUserPassword.value)) {
		complexity.value.case = true;
	} else {
		complexity.value.case = false;
  }
  if (character.test(newUserPassword.value)) {
		complexity.value.special = true;
	} else {
		complexity.value.special = false;
  }
};
watch(() => [new_user.value.firstName,new_user.value.lastName,new_user.value.email,newUserPassword.value], ([newV1,newV2,newV3,newV4]) =>
{
	let hasNumber = /\d/;
	let uppercase = /[A-Z]/;
	let character = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  let ifEmail = /^[^@]+@[^@]+\.[^@]+$/;
  if(newV1 != '' && newV2 != '' && ifEmail.test(newV3) && hasNumber.test(newV4) && uppercase.test(newV4) && character.test(newV4) && newV4.length >= 8){
    saveNewUserButton.value = true;
  } else {
    saveNewUserButton.value = false;
  }
});
watch(newUserPassword, () =>
	watchPass(), {
	immediate: true
});
watch(() => displayScreen.value, () =>
{
  if (user.metadata.changePass === 'true') {
    displayScreen.value = 'password';
  }
});
</script>

<style lang="scss" scoped>
  .groups_field{
    margin-top: 10px;
    border: 1px solid #bcbcbc;
    border-radius: 16px;
    padding: 20px;
    .settings{
      .toggler_field{
        display: flex;
        flex-direction: row;
        align-content: center;
        .toggle-container{
          display: flex;
          align-content: center;
          align-items: center;
        }
      }
      .toggler_field:not(:first-child){
        margin-top: 16px;
      }
      .password_field{
        // margin-top: 20px;
        position: relative;
        input{
          height: 40px;
          line-height: 38px;
          box-sizing: border-box;
          outline: none;
          border: 1px solid #BCBCBC;
          border-radius: 8px;
          padding: 0 0 0 8px;
          width: 100%;
          font-size: 1rem;
          color: #252525;
        }
        p{
          margin: 0 0 10px;
        }
        .stages{
          margin-bottom: 25px;
        }
        .eye {
          display: inline-block;
          height: 40px;
          width: 40px;
          position: absolute;
          right: 5px;
          top: 40px;
          background-image: url(../../public/img/password.png);
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
          @media screen and (max-width: 1024px) {
            top: 37px;
          }
          @media screen and (max-width: 900px) {
            top: 35px;
          }
          span {
            display: inline-block;
            height: 27px;
            width: 2px;
            background-color: #787878;
            transform: rotate(60deg);
            position: relative;
            top: 7px;
            right: -19px;
          }
        }
      }
    }
  }
  .groups_access_list{
    display: flex;
    flex-direction: column;
    min-width: 100px;
    max-height: 290px;
    overflow-y: auto;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: calc(50% - 10px);
      right: 40px;
      height: 20px;
      width: 20px;
      background-color: #fff;
      border: 2px solid #787878;
      box-sizing: border-box;
      border-radius: 6px;
    }
    label{
      position: relative;
      margin: 5px 0;
      width: 100%;
      text-indent: 20px;
      height: 30px;
      align-items: center;
      display: flex;
    }
    label input:checked ~ .checkmark {
      background-color: #2884C7;
      border: 2px solid #2884C7;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    label input:checked ~ .checkmark:after {
      display: block;
    }
    label .checkmark:after {
      top: -1px;
      left: -1px;
      width: 18px;
      height: 18px;
      border-radius: 6px;
      background-image: url(../../public/img/check.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px;
    }
  }
  .main_v8{
    padding: 0px;
    background-color: #fff;
    position: absolute;
    bottom: 72px;
    @media screen and (max-width: 900px){
      bottom: 64px;
    }
    .delete_user{
      display: flex;
      flex-direction: column;
        input[type="text"] {
          margin-top: 5px;
          height: 40px;
          line-height: 40px;
          border: none;
          outline: none;
          border-radius: 8px;
          background-color: #F44336;
          font-size: 1rem;
          color: #fff;
          text-indent: 10px;
        }
        input[type="text"]::placeholder {
          color: #fff;
        }
        input[type="checkbox"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        .checkmark {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0px;
          height: 28px;
          width: 28px;
          background-color: #fff;
          border: 3px solid #787878;
          box-sizing: border-box;
          border-radius: 50%;
        }
        label{
          position: relative;
          margin: 5px 0 15px;
          width: 100%;
          text-indent: 45px;
          height: 24px;
          line-height: 24px;
        }
        label input:checked ~ .checkmark {
          border: 3px solid #099F45;
        }
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }
        label input:checked ~ .checkmark:after {
          display: block;
        }
        label .checkmark:after {
          content: '';
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background-color: #006C3E;
        }
      }
    .delete_entity{
      width: 375px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: fixed;
      z-index: 2;
      background-color: #fff;
      border-radius: 16px;
      padding: 20px;
      box-sizing: border-box;
      h3{
        margin: 0px;
        position: relative;
        span{
          height: 30px;
          width: 30px;
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          background-repeat: no-repeat;
          background-size: 30px;
          background-position: center;
          background-image: url(../../public/img/close.svg);
          cursor: pointer;
        }
      }
      .single{
        width: 100%;
        margin-top: 30px;
      }
      .buttons{
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .button{
          width: calc(50% - 8px);
        }
      }
    }
    .add_notice{
      p{
        margin: 16px;
      }
    }
    .edit_groups,.add_notice{
      width: 550px;
      max-width: calc(100% - 30px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: fixed;
      z-index: 2;
      background-color: #fff;
      border-radius: 16px;
      padding: 20px;
      box-sizing: border-box;
      overflow: hidden;
      h3:nth-child(1){
        margin: -20px -20px 0 -20px;
        padding: 15px 20px;
        position: relative;
        background-color: #f2f2f2;
        span{
          height: 30px;
          width: 30px;
          display: inline-block;
          position: absolute;
          top: 33px;
          right: 20px;
          transform: translateY(-50%);
          background-repeat: no-repeat;
          background-size: 30px;
          background-position: center;
          background-image: url(../../public/img/close.svg);
          cursor: pointer;
        }
      }
      h3:nth-child(2){
        margin: 15px;
      }
      .buttons{
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .button{
          width: calc(50% - 8px);
        }
      }
      
    }
    .slider_modal{
      width: 550px;
      position: fixed;
      z-index: 2;
      right: 0px;
      top: 0px;
      bottom: 0px;
      background-color: #fff;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 480px) {
        // padding: 0 0 80px 15px;
        width: calc(100% - 30px);
        max-height: calc(100% - 30px);
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
        // top: 15px;
        bottom: auto;
        right: auto;
        border-radius: 12px;
        // overflow: hidden;
        overflow-x: auto;
      }
      h3{
        margin: 0px;
        height: 64px;
        line-height: 64px;
        padding: 0 20px;
        background-color: #f2f2f2;
        position: relative;
        span{
          height: 30px;
          width: 30px;
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          background-repeat: no-repeat;
          background-size: 30px;
          background-position: center;
          cursor: pointer;
        }
        .delete{
          background-image: url(../../public/img/delete.svg);
        }
        .close{
          background-image: url(../../public/img/close.svg);
        }
      }
      .modal_body{
        padding: 10px 30px 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 480px) {
          padding: 10px 16px 16px;
        }
        .checkboxes{
          background-color: #7878780D;
          border-radius: 16px;
          padding: 0 20px 20px;
          p{
            margin: 10px 0 10px 0;
            height: 40px;
            line-height: 40px;
          }
          .checks{
            background-color: #fff;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }
            .checkmark {
              position: absolute;
              top: calc(50% - 10px);
              right: 20px;
              height: 20px;
              width: 20px;
              background-color: #fff;
              border: 2px solid #787878;
              box-sizing: border-box;
              border-radius: 6px;
            }
            label{
              position: relative;
              margin: 5px 0;
              width: 100%;
              text-indent: 20px;
            }
            label input:checked ~ .checkmark {
              background-color: #2884C7;
              border: 2px solid #2884C7;
            }
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }
            label input:checked ~ .checkmark:after {
              display: block;
            }
            label .checkmark:after {
              top: 0px;
              left: -1px;
              width: 18px;
              height: 18px;
              border-radius: 6px;
              background-image: url(../../public/img/check.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: 12px;
            }
          }
        }
        .field{
          margin: 10px 0;
          height: 40px;
          line-height: 40px;
          display: flex;
          flex-direction: row;
          
          @media screen and (max-width: 480px) {
            height: auto;
          }
          p{
            margin: 0;
          }
        }
        .toggler{
          align-items: center;
          p{
            width: 210px;
            flex-shrink: 0;
          }
        }
        .short_field{
          align-items: center;
          @media screen and (max-width: 480px) {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            textarea,input{
              flex-shrink: 0;
              flex-grow: 1;
              width: 100%;
            }
            p{
              text-align: left;
            }
          }
          p{
            width: 150px;
            flex-shrink: 0;
          }
          input{
            height: 40px;
            line-height: 38px;
            box-sizing: border-box;
            outline: none;
            border: 1px solid #BCBCBC;
            border-radius: 8px;
            padding: 0 0 0 8px;
            flex-grow: 1;
            font-size: 1rem;
            color: #252525;
            font-family: 'Inter', sans-serif;
          }
          textarea{
            resize: none;
            height: 330px;
            box-sizing: border-box;
            outline: none;
            border: 1px solid #BCBCBC;
            border-radius: 8px;
            padding: 5px 0 0 8px;
            flex-grow: 1;
            font-size: 1rem;
            color: #252525;
            align-self: start;
            font-family: 'Inter', sans-serif;
          }
          .edit_toggle{
            display: inline-block;
            margin-left: auto;
            width: 40px;
            height: 40px;
            background-image: url(../../public/img/edit.svg);
            background-size: 30px;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
          }
        }
        .buttons{
          margin-top: auto;
          display: flex;
          justify-content: space-between;
          
          @media screen and (max-width: 480px) {
            margin-top: 16px;
          }
          .button{
            width: calc(50% - 8px);
          }
        }
      }
    }
    .sidebar{
      padding: 0 20px;
      position: sticky;
      height: 100%;
      top: 0px;
      left: 0px;
      width: 340px;
      box-sizing: border-box;
      box-shadow: 1px -1px 4px 0px #0000000F;
      @media screen and (max-width: 900px) {
        position: fixed;
        background-color: #fff;
        z-index: 2;
      }
      .my_settings,.company_settings{
        padding: 20px 0;
        div{
          height: 50px;
        }
        >p{
          height: 50px;
          line-height: 50px;
          margin: 0;
          color: #444;
          display: flex;
          align-items: center;
          border-radius: 8px;
        }
        .menu_item{
          transition: all .2s ease-in-out;
          margin-bottom: 4px;
          svg{
            display: inline-block;
            height: 50%;
            aspect-ratio: 2/1;
            background-repeat: no-repeat;
            background-size: 24px;
            background-position: center;
          }
        }
        .menu_item:hover,.menu_item.active{
          background-color: #099F45;
          color: #fff;
          cursor: pointer;
        }
        .user{
          margin: 20px 0;
          display: flex;
          flex-direction: row;
          >div{
            height: 50px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            justify-content: center;
            p{
              color: #252525;
              font-weight: 500;
              margin: 0;
            }
            span{
              display: inline-block;
            }
          }
          .no_image{
            display: inline-block;
            min-height: 50px;
            min-width: 50px;
            margin-left: 0px;
            display: flex;
            text-align: center;
            justify-content: center;
            /* background-color: #e8e8e8; */
            border-radius: 50%;
            cursor: default;
            background-image: url(../../public/img/user_management/account.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
        }
      }
      .divider{
        height: 1px;
        width: 100%;
        background-color: #2525251A;
        margin: 10px 0;
      }
    }
    .work_area{
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
      box-sizing: border-box;
    }
  }
  .context_shadow {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
  }
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: all 0.2s ease;
  }
  .slide-right-enter-from,
  .slide-right-leave-to {
    margin-right: -500px;
  }

  .sidebar_slide-enter-active,
  .sidebar_slide-leave-active{
    transition: all .2s ease-in-out;
  }
  .sidebar_slide-enter-from,
  .sidebar_slide-leave-to{
    margin-left: -340px;
  }
</style>


